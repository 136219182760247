import { CookieKeys, setClientCookie } from '@finn/ui-utils';
import queryString from 'query-string';
import { useEffect } from 'react';

const CLICK_ID_COOKIE_EXPIRY_IN_DAYS = 30;

export const useSetClickIdCookies = () => {
  useEffect(() => {
    const { utm_source, awc, clickid } = queryString.parse(
      window.location.search
    );
    // google, reddit and linkedIn cookies are set automatically with some other script
    // so here we set awin and tisoomi cookies only
    const isAwin = utm_source === 'awin';
    const isTisoomi = utm_source === 'tisoomi';
    if (isAwin && awc) {
      setClientCookie(
        CookieKeys.AWIN_CLID,
        awc,
        CLICK_ID_COOKIE_EXPIRY_IN_DAYS
      );
    }
    if (isTisoomi && clickid) {
      setClientCookie(
        CookieKeys.TISOOMI_CLID,
        clickid,
        CLICK_ID_COOKIE_EXPIRY_IN_DAYS
      );
    }
  }, []);
};
